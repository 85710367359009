<template>
  <v-dialog
    v-model="dialog"
    scrollable
    :persistent="this.uploading"
    max-width="500px"
    transition="dialog-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        color="color3 color3Text--text"
        fab small
        v-on="on"
      >
        <v-icon>fas fa-cloud-upload-alt</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-toolbar color="color1 color1Text--text">
        <v-toolbar-title>Bid Upload</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="color2 color2Text--text"
          fab small
          @click.stop="dialog = false; window = 0"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pa-2">
        <v-window v-model="window" touchless>
          <!-- FILE PICKER -->
          <v-window-item :value="0">
            <v-card color="grey lighten-4">
              <v-container>
                <v-row dense>
                  <v-col cols="12" class="title">
                    {{ currentFile ? 'You are currently using:' : 'Which file would you like to upload?'}}
                  </v-col>
                  <v-expand-transition>
                    <v-col cols="12" v-if="currentFile" class="subheading">
                      {{currentFile.name}}
                    </v-col>
                  </v-expand-transition>
                  <v-col cols="12">
                    <v-btn
                      raised
                      large
                      class="color3 white--text"
                      @click="onPickFile"
                      :disabled="busy"
                    >{{`Choose a ${currentFile ? 'new ' : ''}File`}}</v-btn>
                    <input
                      v-if="dialog"
                      type="file"
                      style="display: none"
                      ref="fileInput"
                      accept=".csv"
                      @change="onFilePicked">
                </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-window-item>
          <!-- VERIFY DATA -->
          <v-window-item :value="1">
            <v-card color="grey lighten-4">
              <v-container>
                <v-row dense>
                  <v-col cols="12" class="title">
                    Make sure this is the right data
                    <div class="subheading mt-1">Double check if the column headers are correct and uncheck the box below if your file did not have headers</div>
                  </v-col>
                  <v-col cols="12" class="title">
                    <v-checkbox
                      color="color3"
                      label="My file has column headers"
                      v-model="hasHeader"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12">
                    <v-data-table
                      :items="rows"
                      :headers="headers"
                      v-if="parseResuls"
                      class="elevation-1"
                    >
                    </v-data-table>
                </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-window-item>

        </v-window>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="color3 color3Text--text"
          :loading="uploading"
          :disabled="!bids"
          @click.stop="upload"
        >Upload</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Bid from '@/classes/Bid'
import Papa from 'papaparse'

export default {
  props: ['program', 'tournamentId', 'options'],
  data () {
    return {
      dialog: false,
      window: 0,
      currentFile: null,
      hasHeader: true,
      parseResuls: null,
      busy: false,
      uploading: false
    }
  },
  computed: {
    rows () {
      return this.parseResuls && this.parseResuls.data
    },
    headers () {
      const head = []
      if (this.parseResuls) {
        for (const prop in this.parseResuls.data[0]) {
          head.push({
            text: this.hasHeader ? `${prop}` : `Col ${prop}`,
            value: `${prop}`,
            sortable: false,
            align: 'left'
          })
        }
      }
      return head
    },
    bids () {
      return this.rows && this.rows.map(r => {
        var divisions = this.options.divisions.filter(f => r.Division.includes(f.name))

        const bid = {
          bidProgramId: this.program.id,
          subPolicy: this.program.subPolicy,
          discountAmount: this.program.discountAmount,
          discountPercentage: this.program.discountPercentage,
          tournamentId: this.tournamentId,
          earnedAt: r['Qualified at:'],
          earnedFor: r.Division,
          dtEarned: r['Tournament Date'],
          players: [
            {
              aauNumber: r['AAU #'],
              email: r.Email,
              firstName: r['Athlete 1 First Name'],
              lastName: r['Athlete 1 Last Name']
            },
            {
              aauNumber: r['AAU #2'],
              email: r.Email2,
              firstName: r['Athlete 2 First Name'],
              lastName: r['Athlete 2 Last Name']
            }
          ],
          divisionList: divisions ? divisions.map(m => m.id) : []
        }
        return bid
      })
    }
  },
  methods: {
    onPickFile () {
      this.$refs.fileInput.click()
    },
    onFilePicked (event) {
      const file = event.target.files[0]
      if (!file) return
      if (file.name.lastIndexOf('.csv') <= 0) {
        alert('Please select a CSV file')
        return
      }
      this.busy = true
      this.currentFile = file
      this.parseFile()
      this.window++
      this.busy = false
    },
    parseFile () {
      Papa.parse(this.currentFile, {
        header: this.hasHeader,
        complete: (results, file) => {
          this.currentFile = file

          for (const e of results.errors) {
            results.data.splice(e.row, 1)
          }
          const checkProperties = (obj) => {
            for (var key in obj) {
              if (obj[key] !== null && obj[key] !== '') return true
            }
            return false
          }

          results.data = results.data.filter((r) => checkProperties(r))

          this.parseResuls = results
        }
      })
    },
    upload () {
      this.uploading = true
      const dto = this.bids
      this.$VBL.post.bidUpload(dto, this.tournamentId)
        .then(r => {
          this.$emit('upload-complete', r.data.map(m => new Bid(m)))
          this.dialog = false
        })
        .catch(e => { console.log(e.response) })
        .finally(() => {
          this.uploading = false
        })
    }
  },
  watch: {
    dialog: function (val) {
      this.window = 0
      this.currentFile = null
      this.hasHeader = true
      this.parseResuls = null
    }
  }
}
</script>

<style>

</style>
